/* eslint-disable */
import helpers from 'helpers'
import { getVerifyUrl } from 'data/api'

const proveFair = function(gameId) {
  return new Promise(proveFairPromise.bind(this, gameId))
}

let proveFairPromise = function(gameId, resolve, reject) {
  let url = getVerifyUrl(gameId)
  fetch(url, {
    method: 'GET',
    cache: 'no-cache',
  })
    .then(helpers.handleErrors)
    .then(response => response.json())
    .then(response => {
      let gamedata = response.payload

      let { depositTxHash, plainTextSeed, randomSeed } = gamedata
      let generatedRandomNumberSeed, generatedServerSeedHash

      let randomNumber = getRandomNumber(randomSeed)

      if (plainTextSeed) {
        let randomSeedCheck = plainTextSeed
        let voutHex = gamedata.vout.toString(16)
        if (voutHex.length < 8) {
          let neededZeroes = 8 - voutHex.length
          for (let i = 0; i < neededZeroes; i++) {
            voutHex = '0' + voutHex
          }
        }
        randomSeedCheck = randomSeedCheck + depositTxHash + voutHex
        generatedRandomNumberSeed = helpers.sha256(
          hexStringToByte(randomSeedCheck)
        )
        generatedServerSeedHash = helpers.sha256(hexStringToByte(plainTextSeed))
      }

      let provablyFair =
        randomNumber === gamedata.roll &&
        gamedata.win === randomNumber < gamedata.bet

      //Only check this if the plain text hash has been released
      if (generatedServerSeedHash) {
        provablyFair =
          provablyFair && generatedServerSeedHash === gamedata.serverSeedHash
        provablyFair = provablyFair && generatedRandomNumberSeed === randomSeed
      }

      resolve(provablyFair)
    })
    .catch(error => {
      console.error(error)
      reject(error)
    })
}

function getRandomNumber(sha256) {
  let mersenneTwister = new helpers.MersenneTwister()
  let seed = []
  for (let s = 0; s < 8; s++) {
    let intText = sha256.substring(s * 8, (s + 1) * 8)
    seed.push(parseInt(intText, 16))
    mersenneTwister.seedArray(seed)
  }
  return mersenneTwister.short()
}

function hexStringToByte(str) {
  if (!str) {
    return new Uint8Array()
  }

  let a = []
  for (let i = 0, len = str.length; i < len; i += 2) {
    a.push(parseInt(str.substr(i, 2), 16))
  }

  return new Uint8Array(a)
}

export default proveFair
