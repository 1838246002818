import styled, { css } from 'styled-components'
import { media } from 'styles/utils'
import H1 from 'atoms/H1'
import palette from 'styles/colors'

export const Row = styled.div`
  display: grid;
  justify-content: center;
  padding: 10px;
  margin: 15px 0;
  background: ${props => (props.hover ? palette.alabaster : palette.fanny)};
  border: 1px solid #eee;
  border-radius: 5px;
  box-shadow: 0 1px 3px ${palette.muted};
  width: 100%;
  box-sizing: border-box;

  &:hover {
    background: ${palette.alabaster};
  }

  grid-template-areas: 'label' 'value';
  grid-template-columns: 1fr;
  ${media.medium`
    grid-template-areas: 'label value';
    grid-template-columns: 1fr 4fr;
  `}

  ${props =>
    props.fairgame &&
    css`
      grid-template-areas: 'date' 'seedhash' 'seedtext';
      grid-template-columns: 1fr;
      ${media.medium`
      grid-template-areas: 'date seedhash seedtext';
      grid-template-columns: 1fr repeat(2, 2fr);
    `}
    `}
`

export const Col = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  word-break: break-all;
  text-align: center;
  overflow: hidden;

  justify-content: center;
  align-items: center;
  flex: 1;
  ${props =>
    props.area &&
    css`
      grid-area: ${props.area};
    `}
  ${media.medium`
        margin: auto 10px;
    `}
`

export const Link = styled.a`
  text-decoration: none;
`
export const Wrapper = styled.div`
  width: 100%;
`

export const Title = styled(H1)`
  width: 100%;
`
