import React from 'react'

import FairContent from 'components/FairContent'
import Layout from 'components/Layout'
import { connect } from 'react-redux'

import { getFairgames, getVerifiedGame } from 'data/fair/selectors'
import { fetchFairgames, fetchVerifiedGame } from 'data/fair/actions'

class FairPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      id: '',
    }
  }
  componentDidMount() {
    const { fetchFairgames, fetchVerifiedGame } = this.props
    const searchQuery = window.location.search

    const id = getUrlParam('id', searchQuery)
    this.setState({
      id,
    })

    if (!id) {
      fetchFairgames()
    }

    if (id) {
      fetchVerifiedGame(id)
    }
  }

  render() {
    const { id } = this.state
    const {
      pageContext: { locale },
      fairgames,
      verifiedGame,
    } = this.props

    return (
      <Layout locale={locale} layout="dice">
        <FairContent
          fairGames={fairgames}
          id={id}
          verifiedGame={verifiedGame}
        />
      </Layout>
    )
  }
}

const getUrlParam = (name, search) => {
  const regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
  const result = regex.exec(search)
  return result === null
    ? ''
    : decodeURIComponent(result[1].replace(/\+/g, ' '))
}

const mapStateToProps = state => {
  const fairgames = getFairgames(state)
  const verifiedGame = getVerifiedGame(state)
  return {
    fairgames,
    verifiedGame,
  }
}
const mapDispatchToProps = {
  fetchFairgames,
  fetchVerifiedGame,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FairPage)
