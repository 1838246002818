import {
  FETCH_FAIR_GAMES_BEGIN,
  FETCH_FAIR_GAMES_FAIL,
  FETCH_FAIR_GAMES_SUCCESS,
  FETCH_VERIFIED_GAME_BEGIN,
  FETCH_VERIFIED_GAME_FAIL,
  FETCH_VERIFIED_GAME_SUCCESS,
} from './constants'

import { FAIR_URL, getVerifyUrl } from '../api'

const fetchFairgamesBegin = () => ({ type: FETCH_FAIR_GAMES_BEGIN })
const fetchFairgamesFail = () => ({ type: FETCH_FAIR_GAMES_FAIL })
const fetchFairGamesSuccess = data => {
  return {
    type: FETCH_FAIR_GAMES_SUCCESS,
    payload: data,
  }
}

const fetchVerifiedGameBegin = () => ({ type: FETCH_VERIFIED_GAME_BEGIN })
const fetchVerifiedGameFail = () => ({ type: FETCH_VERIFIED_GAME_FAIL })
const fetchVerifiedGameSuccess = data => ({
  type: FETCH_VERIFIED_GAME_SUCCESS,
  payload: data,
})

export const fetchFairgames = () => {
  return async dispatch => {
    dispatch(fetchFairgamesBegin())
    try {
      const result = await fetch(FAIR_URL)
      const text = await result.text()
      const json = await JSON.parse(text)
      dispatch(fetchFairGamesSuccess(json.payload))
    } catch (error) {
      console.error(error)
      dispatch(fetchFairgamesFail())
    }
  }
}

export const fetchVerifiedGame = gameid => {
  return async dispatch => {
    dispatch(fetchVerifiedGameBegin())
    try {
      const result = await fetch(getVerifyUrl(gameid))
      const text = await result.text()
      const json = await JSON.parse(text)
      dispatch(fetchVerifiedGameSuccess(json.payload))
    } catch (error) {
      console.error(error)
      dispatch(fetchVerifiedGameFail())
    }
  }
}
