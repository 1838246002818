import React from 'react'
import styled from 'styled-components'

import 'assets/dice.css'

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  width: 100%;
  text-align: center;
  height: 200px;
  transform: scale(0.3);
`

const Loading = () => (
  <Wrapper>
    <div id="dice">
      <div className="side front">
        <div className="dot center" />
      </div>
      <div className="side front inner" />
      <div className="side top">
        <div className="dot dtop dleft" />
        <div className="dot dbottom dright" />
      </div>
      <div className="side top inner" />
      <div className="side right">
        <div className="dot dtop dleft" />
        <div className="dot center" />
        <div className="dot dbottom dright" />
      </div>
      <div className="side right inner" />
      <div className="side left">
        <div className="dot dtop dleft" />
        <div className="dot dtop dright" />
        <div className="dot dbottom dleft" />
        <div className="dot dbottom dright" />
      </div>
      <div className="side left inner" />
      <div className="side bottom">
        <div className="dot center" />
        <div className="dot dtop dleft" />
        <div className="dot dtop dright" />
        <div className="dot dbottom dleft" />
        <div className="dot dbottom dright" />
      </div>
      <div className="side bottom inner" />
      <div className="side back">
        <div className="dot dtop dleft" />
        <div className="dot dtop dright" />
        <div className="dot dbottom dleft" />
        <div className="dot dbottom dright" />
        <div className="dot center dleft" />
        <div className="dot center dright" />
      </div>
      <div className="side back inner" />
      <div className="side cover x" />
      <div className="side cover y" />
      <div className="side cover z" />
    </div>
  </Wrapper>
)

export default Loading
